import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { alpha, Avatar, Box, Skeleton, useTheme } from "@mui/material";
import {
  TokenIconController,
  TokenIconControllerServiceProviderName,
} from "james/financial/TokenIconController";
import { ScopeDeterminer } from "james/search/scope";
import { Permission } from "james/security/Permission";
import {
  PublishRounded as UploadIcon,
  TollOutlined as TokenIcon,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { doUpload } from "utilities/network/upload";
import { Token } from "james/ledger";
import {
  AllStellarNetworks,
  IsLiquidityPoolToken,
  LiquidityPoolTokenToReserveTokens,
  StellarNetwork,
} from "james/stellar";
import { useStellarContext } from "context/Stellar";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { useApplicationContext } from "context/Application/Application";
import { useErrorContext } from "context/Error";
import { FutureToken } from "@mesh/common-js/dist/ledger/futureToken_pb";
import { Token as StellarToken } from "@mesh/common-js/dist/stellar/token_pb";
import {
  futureTokenFromStellarToken,
  futureTokenToStellarToken,
} from "@mesh/common-js/dist/ledger";

export interface Props {
  id?: string;
  token: Token | FutureToken | StellarToken;
  size?: number;
  tokenIconDownloadURL?: string;
  tokenOwnerID?: string;
  disableChangeIcon?: boolean;
  outsideBorder?: boolean;
}

const allowedImageTypes = ["image/png", "image/jpg", "image/jpeg"];

export function IconViewUpload(props: Props) {
  const _token = useMemo<Token>(() => {
    if (props.token instanceof Token) {
      return props.token as Token;
    } else if (props.token instanceof FutureToken) {
      return Token.fromFutureToken(props.token);
    } else {
      return Token.fromFutureToken(futureTokenFromStellarToken(props.token));
    }
  }, [props.token]);
  const { errorContextErrorTranslator } = useErrorContext();
  const theme = useTheme();
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { authContext } = useApplicationContext();
  const { stellarContextGetTokenIconURL } = useStellarContext();
  const { enqueueSnackbar } = useSnackbar();
  const [userCanChangeIcon, setUserCanChangeIcon] = useState(false);
  const [loadingTokenOwnerAndPerms, setLoadingTokenOwnerAndPerms] =
    useState(false);
  const [loadingDLURL, setLoadingDLURL] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [downloadURL, setDownloadURL] = useState(props.tokenIconDownloadURL);
  const [mouseOver, setMouseOver] = useState(false);
  const [reloadToggle, setReloadToggle] = useState(false);
  const inputElRef = useRef<HTMLInputElement>(null);
  const size = props.size ? props.size : 30;
  const [imageVertical, setImageVertical] = useState(false);
  const tokenIconImgRef = useRef<HTMLImageElement>(null);
  const isStellarLiquidityPoolToken = useMemo(() => {
    if (
      _token.network === StellarNetwork.TestSDFNetwork ||
      _token.network === StellarNetwork.PublicNetwork
    ) {
      return IsLiquidityPoolToken(
        futureTokenToStellarToken(_token.toFutureToken()),
      );
    }
    return false;
  }, [props.token]);

  // determine if user has permission to update token icon
  useLayoutEffect(() => {
    // do nothing if this is a liquidity pool token
    if (isStellarLiquidityPoolToken) {
      return;
    }

    // do nothing if token icon change is disabled
    if (props.disableChangeIcon) {
      return;
    }

    let isMounted = true;
    (async () => {
      if (isMounted) {
        setLoadingTokenOwnerAndPerms(true);
      }

      // determine token owner
      let ownerID = props.tokenOwnerID;
      if (!ownerID) {
        try {
          // retrieve the on platform token
          ownerID = (await getLedgerTokenViewModel(_token)).ownerID;
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error determining token owner: ${
              err.message ? err.message : err.toString()
            }`,
          );
          if (isMounted) {
            setLoadingTokenOwnerAndPerms(false);
          }
          return;
        }
      }

      try {
        const determineScopeAuthorisationByRolesResponse = (
          await ScopeDeterminer.DetermineScopeAuthorisationByRoles({
            context: authContext,
            groupID: ownerID,
            buildScopeTree: false,
            service: new Permission({
              serviceName: "RequestIconUploadURLForToken",
              serviceProvider: TokenIconControllerServiceProviderName,
              description: "?",
            }),
          })
        ).authorized;
        if (isMounted) {
          setUserCanChangeIcon(determineScopeAuthorisationByRolesResponse);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error determining if user has permission to change token upload url: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }
      if (isMounted) {
        setLoadingTokenOwnerAndPerms(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [
    isStellarLiquidityPoolToken,
    props.disableChangeIcon,
    props.tokenOwnerID,
    authContext,
    props.token,
  ]);

  // retrieve download url
  useLayoutEffect(() => {
    // do nothing if this is a liquidity pool token or a download url has been given
    if (isStellarLiquidityPoolToken || props.tokenIconDownloadURL) {
      return;
    }

    // icon url not given - try and fetch one
    let isMounted = true;
    (async () => {
      if (isMounted) {
        setLoadingDLURL(true);
      }

      // if this is a stellar token then try to get a token icon url from stellar
      if (AllStellarNetworks.includes(_token.network as StellarNetwork)) {
        // get token icon download url
        const iconDownloadURL = await stellarContextGetTokenIconURL(_token);

        // set token icon as retrieved from stellar if found
        if (iconDownloadURL) {
          if (isMounted) {
            setDownloadURL(iconDownloadURL);
            setLoadingDLURL(false);
          }
          return;
        }
      }

      // retrieve token icon url from mesh backend
      try {
        const requestIconDownloadURLForToken = (
          await TokenIconController.RequestIconDownloadURLForToken({
            context: authContext,
            token: _token,
          })
        ).downloadURL;
        if (isMounted) {
          setDownloadURL(requestIconDownloadURLForToken);
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error loading view/upload icon state: ${
            err.message ? err.message : err.toString()
          }`,
        );
      }

      if (isMounted) {
        setLoadingDLURL(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [
    stellarContextGetTokenIconURL,
    isStellarLiquidityPoolToken,
    props.tokenIconDownloadURL,
    authContext,
    props.token,
    reloadToggle,
  ]);

  const handleUploadClick = () => {
    if (inputElRef !== null && inputElRef.current) {
      inputElRef.current.click();
    }
  };

  const handleUploadIcon = async () => {
    // get a handle on selected file
    if (!inputElRef.current) {
      console.error("upload input ref is null");
      return;
    }
    if (!(inputElRef.current.files && inputElRef.current.files.length)) {
      console.error("no upload input ref files");
      return;
    }
    const fileToUpload = inputElRef.current.files[0];
    if (!fileToUpload) {
      console.error("file to upload is null");
      return;
    }

    // ensure that the file type is valid
    if (!allowedImageTypes.includes(fileToUpload.type)) {
      enqueueSnackbar("File Type Not Supported", { variant: "warning" });
      return [
        {
          message: "Unsupported File Type",
          code: "unsupported-file-type",
        },
      ];
    }

    setLoadingUpload(true);

    let uploadURL = "";
    try {
      uploadURL = (
        await TokenIconController.RequestIconUploadURLForToken({
          context: authContext,
          token: _token,
        })
      ).uploadURL;
    } catch (e) {
      console.error("error getting upload url");
      enqueueSnackbar("Error Uploading Image", { variant: "error" });
      setLoadingUpload(false);
      return;
    }

    // perform upload
    try {
      await (() =>
        new Promise<void>((resolve, reject) => {
          fileToUpload.arrayBuffer().then((arrayBuffer) => {
            doUpload({
              url: uploadURL,
              documentData: arrayBuffer,
              documentType: fileToUpload.type,
              onComplete: resolve,
              onError: reject,
            });
          });
        }))();
    } catch (e) {
      console.error("error doing upload");
      enqueueSnackbar("Error Uploading Image", { variant: "error" });
      setLoadingUpload(false);
      return;
    }

    setLoadingUpload(false);
    setReloadToggle(!reloadToggle);
  };

  const loading = loadingDLURL || loadingTokenOwnerAndPerms || loadingUpload;

  // if this is a liquidity pool token then recursively render twice
  if (isStellarLiquidityPoolToken) {
    try {
      const [tokenA, tokenB] = LiquidityPoolTokenToReserveTokens(
        futureTokenToStellarToken(_token.toFutureToken()),
      );
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: theme.spacing(0.5),
          }}
        >
          <IconViewUpload
            id={props.id ? `${props.id.replace(/'/g, "")}-tokenA` : undefined}
            token={tokenA}
            size={props.size}
            disableChangeIcon
          />
          <IconViewUpload
            id={props.id ? `${props.id.replace(/'/g, "")}-tokenB` : undefined}
            token={tokenB}
            size={props.size}
            disableChangeIcon
          />
        </Box>
      );
    } catch (e) {
      console.error(
        `error converting liquidity pool token into reserve tokens: ${e}`,
      );
      return <div>-</div>;
    }
  }

  // show skeleton when loading
  if (loading) {
    return (
      <Skeleton
        variant="circular"
        height={props.size ? props.size : 30}
        width={props.size ? props.size : 30}
      />
    );
  }

  // otherwise try to render the avatar
  return (
    <div
      onClick={(e) => {
        if (!userCanChangeIcon) return;
        if (e && e.stopPropagation) {
          e.stopPropagation();
        }
        handleUploadClick();
      }}
    >
      <Avatar
        sx={{
          height: size,
          width: size,
          backgroundColor: theme.palette.text.primary,
          ...(props.outsideBorder && {
            boxSizing: "content-box",
            border: `2px solid ${theme.palette.custom.midnight}`,
          }),
        }}
        id={`iconViewUpload-iconImage-avatar-${props.id ? props.id.replace(/'/g, "") : ""}`}
        onMouseEnter={() => {
          if (!userCanChangeIcon) {
            return;
          }
          setMouseOver(true);
        }}
      >
        {downloadURL ? (
          <img
            alt=""
            onLoad={() => {
              if (tokenIconImgRef.current) {
                setImageVertical(
                  tokenIconImgRef.current.naturalWidth <
                    tokenIconImgRef.current.naturalHeight,
                );
              }
            }}
            ref={tokenIconImgRef}
            src={downloadURL}
            style={imageVertical ? { height: size } : { width: size }}
          />
        ) : (
          <TokenIcon
            sx={{
              color: theme.palette.custom.midnight,
              fontSize: 0.6 * size,
            }}
          />
        )}
      </Avatar>
      {mouseOver && userCanChangeIcon && (
        <Avatar
          style={{
            backgroundColor: alpha(theme.palette.background.default, 0.5),
            position: "relative",
            cursor: "pointer",
            height: size,
            width: size,
            bottom: size,
            marginBottom: -size,
          }}
          onMouseLeave={() => setMouseOver(false)}
          id={`iconViewUpload-iconImageOverlay-avatar-${props.id ? props.id.replace(/'/g, "") : ""}`}
        >
          <UploadIcon
            sx={{
              color: theme.palette.custom.midnight,
            }}
            style={{ fontSize: 0.6 * size }}
          />
        </Avatar>
      )}
      <input
        id={`iconViewUpload-uploadHiddenInputRef-input-${props.id ? props.id.replace(/'/g, "") : ""}`}
        disabled={loading}
        ref={inputElRef}
        onChange={handleUploadIcon}
        type="file"
        accept={allowedImageTypes.join(",")}
        style={{
          position: "absolute",
          visibility: "hidden",
          width: 0,
          height: 0,
        }}
      />
    </div>
  );
}
