import { FutureAmount } from "@mesh/common-js/dist/ledger/futureAmount_pb";
import { LimitOrderType } from "@mesh/common-js/dist/market/limitOrder_pb";
import {
  bigNumberToDecimal,
  decimalToBigNumber,
} from "@mesh/common-js/dist/num";
import { BigNumber } from "bignumber.js";
import { FormValidationType, validationFunc } from ".";
import { TradeCardProps } from "../components/TradeCard/TradeCard";
import { Decimal } from "@mesh/common-js/dist/num/decimal_pb";
import { Model } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { getAvailableBalance } from "@mesh/common-js/dist/views/stellarAccountView";

export const TradeCardValidations: FormValidationType<TradeCardProps> = {
  estimatedTotal: new FutureAmount().setValue(bigNumberToDecimal(BigNumber(0))),
  amount: new FutureAmount().setValue(bigNumberToDecimal(BigNumber(0))),
  price: new FutureAmount().setValue(bigNumberToDecimal(BigNumber(0))),
  cardOption: LimitOrderType.BUY,
  amountFocused: false,
  loading: false,
  sourceAccount: new Model(),
  potentialSourceAccounts: [],
  valid: true,
  isSignatory: false,
  fee: {
    fee: new FutureAmount().setValue(bigNumberToDecimal(BigNumber(0))),
    vat: new FutureAmount().setValue(bigNumberToDecimal(BigNumber(0))),
  },
  fieldValidations: {
    estimatedTotal: (form) => {
      let error = "";
      let valid = true;

      const estimatedTotal = decimalToBigNumber(form.estimatedTotal.getValue());

      // Switch validation based on order type.
      if (
        form.cardOption === LimitOrderType.BUY &&
        (!form.balance ||
          estimatedTotal.gt(
            decimalToBigNumber(
              getAvailableBalance(form.balance[form.cardOption]).getValue() ??
                new Decimal(),
            ),
          ))
      ) {
        error = "Insufficient Balance";
        valid = false;
      }

      return {
        error,
        valid,
      };
    },
    price: (form) => {
      let error = "";
      let valid = true;

      if (!decimalToBigNumber(form.price.getValue()).gt(0)) {
        error = "Price should be greater than 0";
        valid = false;
      }

      return {
        error,
        valid,
      };
    },
    amount: (form) => {
      let error = "";
      let valid = true;

      const amount = decimalToBigNumber(form.amount.getValue());

      if (amount.lt(form.quoteParemeter?.minimumDealSize.value ?? 0.02)) {
        error = `Minimum deal size: ${form.quoteParemeter?.minimumDealSize.value.toFormat(2)} ${form.quoteParemeter?.minimumDealSize.token.code}`;
        valid = false;
      }

      if (amount.gt(form.quoteParemeter?.maximumDealSize.value ?? 1)) {
        error = `Max deal size: ${form.quoteParemeter?.maximumDealSize.value.toFormat(2)} ${form.quoteParemeter?.maximumDealSize.token.code}`;
        valid = false;
      }

      if (
        form.cardOption === LimitOrderType.SELL &&
        (!form.balance ||
          amount.gt(
            decimalToBigNumber(
              getAvailableBalance(form.balance[form.cardOption]).getValue() ??
                new Decimal(),
            ),
          ))
      ) {
        error = "Insufficient Balance";
        valid = false;
      }

      if (!decimalToBigNumber(form.amount.getValue()).gt(0)) {
        error = "Amount should be greater than 0";
        valid = false;
      }

      return {
        error,
        valid,
      };
    },
  },
  fieldErrors: {
    price: "Price must be set",
  },
  isValid: <T>(
    form: T,
    fieldValidations: Partial<Record<keyof T, validationFunc<T>>>,
  ) => {
    const validations: Record<string, string> = {};
    let valid = true;
    Object.keys(fieldValidations).forEach((key) => {
      const r = fieldValidations[key as keyof T]?.(form);
      if (r && !r.valid) {
        validations[key] = r.error;
        valid = false;
      }
    });

    return {
      fieldErrors: validations,
      valid: valid,
    };
  },
};
