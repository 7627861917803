import { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Close as CloseIcon,
  InfoOutlined as InfoIcon,
  List as ListingIcon,
  Notes as NoteIcon,
  RemoveCircleOutline as RemoveIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Model as TokenViewModel,
  useRead as userLedgerTokenViewRead,
} from "james/views/ledgerTokenView";
import { useSnackbar } from "notistack";
import { useValidatedForm } from "hooks/useForm";
import {
  formUpdaterSpecs,
  FormUpdaterSpecsType,
  validationFunc,
} from "./useValidatedForm";
import { AssetFetcher, Token } from "james/ledger";
import { TextNumField } from "components/FormFields";
import { TokenIconViewUpload } from "components/Ledger/Token";
import {
  Listing,
  ListingInspector,
  ListingRepository,
  ListingUpdater,
  Mechanism,
  MechanismType,
} from "james/market";
import { Asset } from "james/financial/Asset";
import { TokenIdentifier } from "james/search/identifier";
import { useLedgerTokenViewContext } from "context/LedgerTokenView";
import { Query } from "james/search/query";
import {
  TextExactCriterion,
  TextNINListCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { useLedgerContext } from "context/Ledger";
import cx from "classnames";
import isEqual from "lodash/isEqual";
import { ListingState } from "james/market/Listing";
import { useApplicationContext } from "context/Application/Application";
import { LedgerNetwork } from "james/ledger/Network";
import { ListingStateChip } from "views/MarketListing/MarketListings/Chips";
import { useRQListingStateController } from "hooks/reactQuery/useRQListingStateController";
import { useErrorContext } from "context/Error";
import { BigNumber } from "bignumber.js";

const PREFIX = "ListingManagementDialog";

const classes = {
  dialogTitleRootOverride: `${PREFIX}-dialogTitleRootOverride`,
  dialogContent: `${PREFIX}-dialogContent`,
  infoIcon: `${PREFIX}-infoIcon`,
  row: `${PREFIX}-row`,
  selectFormField: `${PREFIX}-selectFormField`,
  quoteParametersTable: `${PREFIX}-quoteParametersTable`,
  quoteParametersTableRow: `${PREFIX}-quoteParametersTableRow`,
  lastActionAnnotationDialogTitleRootOverride: `${PREFIX}-lastActionAnnotationDialogTitleRootOverride`,
  dialogContentRootOverride: `${PREFIX}-dialogContentRootOverride`,
  textArea: `${PREFIX}-textArea`,
  textAreaError: `${PREFIX}-textAreaError`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogTitleRootOverride}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: theme.spacing(2, 1, 2, 2),
    alignItems: "center",
  },

  [`& .${classes.dialogContent}`]: {
    paddingTop: `${theme.spacing(2)} !important`,
    width: 500,
  },

  [`& .${classes.infoIcon}`]: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },

  [`& .${classes.row}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
  },

  [`& .${classes.selectFormField}`]: {
    paddingRight: theme.spacing(1),
  },

  [`& .${classes.quoteParametersTable}`]: {
    overflowY: "auto",
    overflowX: "hidden",
  },

  [`& .${classes.quoteParametersTableRow}`]: {
    display: "grid",
    gridTemplateColumns: "70px 1fr 1fr auto",
    alignItems: "center",
    columnGap: theme.spacing(1),
  },

  [`& .${classes.lastActionAnnotationDialogTitleRootOverride}`]: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1,
    )}px ${theme.spacing(2)}px`,
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContentRootOverride}`]: {
    width: 540,
  },

  [`& .${classes.textArea}`]: {
    color: theme.palette.text.primary,
    fontSize: 16,
    backgroundColor: theme.palette.background.paper,
    width: 508,
    maxWidth: 508,
  },

  [`& .${classes.textAreaError}`]: {
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

export type ListingManagementDialogProps = {
  closeDialog: () => void;
  baseToken?: Token;
};

export type FormData = {
  baseToken: Token;
  marketMechanisms: Array<Mechanism>;
  lastActionAnnotation: string;
  listingStateChangeAction?: ListingStateChangeAction;
};

export enum ListingStateChangeAction {
  Activate = "Activate",
  Deactivate = "Deactivate",
}

const tokenCategoryCriterion = TextNINListCriterion([
  TokenCategory.LiquidityPoolShares,
  TokenCategory.DigitalInstrument,
  TokenCategory.InstrumentStablecoin,
]);

const ListingManagementDialog = (props: ListingManagementDialogProps) => {
  const { errorContextErrorTranslator } = useErrorContext();
  const { closeDialog } = props;

  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { getLedgerTokenViewModel } = useLedgerTokenViewContext();
  const { ledgerContextGetAssetTokenDescription } = useLedgerContext();

  const [
    formData,
    formDataValidationResult,
    formDataUpdate,
    formDataValidationInProgress,
  ] = useValidatedForm<FormData, FormUpdaterSpecsType>(
    validationFunc,
    undefined,
    formUpdaterSpecs,
    {
      marketMechanisms: [],
      baseToken: new Token(),
      lastActionAnnotation: "",
      listingStateChangeAction: undefined,
    },
    new Set<string>([]),
    { skipTouchedFieldsOnValidation: true },
  );

  // for base token selection
  const {
    readResponse: ledgerTokenViewReadResponseForBaseToken,
    readRequest: ledgerTokenViewReadRequestForBaseToken,
    setReadRequest: setLedgerTokenViewReadRequestForBaseToken,
    loading: ledgerTokenViewReadLoadingForBaseToken,
  } = userLedgerTokenViewRead({
    criteria: {
      tokenCategory: tokenCategoryCriterion,
    },
  });

  // for quote token selection
  const {
    readResponse: ledgerTokenViewReadResponseForQuoteToken,
    readRequest: ledgerTokenViewReadRequestForQuoteToken,
    setReadRequest: setLedgerTokenViewReadRequestForQuoteToken,
    loading: ledgerTokenViewReadLoadingForQuoteToken,
  } = userLedgerTokenViewRead({
    query: new Query({
      ...new Query(),
      limit: 10,
    }),
    criteria: {
      tokenCategory: tokenCategoryCriterion,
    },
  });
  const [quoteTokenSearchText, setQuoteTokenSearchText] = useState("");

  const [listing, setListing] = useState<Listing | undefined>(undefined);
  const [asset, setAsset] = useState<Asset | undefined>(undefined);
  const [assetDescription, setAssetDescription] = useState("");
  const [baseToken, setBaseToken] = useState<Token | undefined>(
    props.baseToken,
  );
  const [baseTokenViewModel, setBaseTokenViewModel] = useState<
    TokenViewModel | undefined
  >(undefined);
  const [loadingListing, setLoadingListing] = useState(false);
  const matchingListingNotSet =
    !baseToken || !listing || !listing.token.isEqualTo(baseToken);
  useEffect(() => {
    if (!baseToken || (listing && listing.token.isEqualTo(baseToken))) {
      // If base token is not set or (it is set)
      // and the listing is set for the base token
      // then do nothing - no data to fetch.
      return;
    }
    // Base token does not match listing.
    // (either listing is not set or it is set for a different base)

    // indicate that listing is being loaded
    setLoadingListing(true);

    (async () => {
      // initialise a matching listing
      let matchingListing = new Listing();
      matchingListing.token = baseToken;
      matchingListing.marketMechanisms.push(
        new Mechanism({
          type: MechanismType.Spot,
          quoteParameters: [],
        }),
      );
      try {
        // if a listing exists for this base token then fetch it
        if (
          (
            await ListingInspector.DoesListingForTokenExist({
              context: authContext,
              token: baseToken,
            })
          ).exists
        ) {
          matchingListing = (
            await ListingRepository.RetrieveListing({
              context: authContext,
              identifier: TokenIdentifier(baseToken),
            })
          ).listing;
        }
      } catch (e) {
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting matching listing: ${
            err.message ? err.message : err.toString()
          }`,
        );
        enqueueSnackbar(`erro getting matching listing`, { variant: "error" });
        setLoadingListing(false);
        return;
      }

      // get other required data
      try {
        await Promise.all([
          (async () => {
            if (!baseToken) {
              console.error("expected base token to be set");
              return;
            }
            setBaseTokenViewModel(await getLedgerTokenViewModel(baseToken));
          })(),
          (async () => {
            if (!baseToken) {
              console.error("expected base token to be set");
              return;
            }
            setAsset(
              (
                await AssetFetcher.FetchAsset({
                  context: authContext,
                  identifier: TokenIdentifier(baseToken),
                })
              ).asset as Asset,
            );
          })(),
          (async () => {
            if (!baseToken) {
              console.error("expected base token to be set");
              return;
            }
            setAssetDescription(
              await ledgerContextGetAssetTokenDescription(baseToken),
            );
          })(),
        ]);
      } catch (e) {
        setLoadingListing(false);
        const err = errorContextErrorTranslator.translateError(e);
        console.error(
          `error getting associated listing data: ${
            err.message ? err.message : err.toString()
          }`,
        );
        return;
      }

      // update potential new quote token search criteria
      setLedgerTokenViewReadRequestForQuoteToken({
        criteria: {
          tokenCategory: tokenCategoryCriterion,
          "token.code": TextNINListCriterion([
            ...matchingListing.marketMechanisms[0].quoteParameters.map(
              (qp) => qp.quoteToken.code,
            ),
            baseToken.code,
          ]),
          "token.network": TextExactCriterion(baseToken.network),
        },
      });

      // set matching listing & terminate loading
      formDataUpdate.baseToken(baseToken);
      formDataUpdate.marketMechanisms(matchingListing.marketMechanisms);
      setListing(matchingListing);
      setLoadingListing(false);
    })();
  }, [
    baseToken,
    listing,
    getLedgerTokenViewModel,
    authContext,
    enqueueSnackbar,
    formDataUpdate,
    ledgerContextGetAssetTokenDescription,
    setLedgerTokenViewReadRequestForQuoteToken,
  ]);

  const {
    ListAsset: ListingStateControllerListAsset,
    ActivateListing: ListingStateControllerActivateAsset,
    DeactivateListing: ListingStateControllerDeactivateListing,
  } = useRQListingStateController();
  const [listingCreationInProgress, setListingCreationInProgress] =
    useState(false);
  const handleCreateListing = useCallback(async () => {
    if (!asset) {
      console.error("expected asset to be set");
      return;
    }

    setListingCreationInProgress(true);

    try {
      await ListingStateControllerListAsset({
        context: authContext,
        assetToken: asset.assetToken(),
        exchangeNetwork: asset.assetToken().network as LedgerNetwork,
        marketMechanisms: formData.marketMechanisms,
        estimatedAnnualReturn: new BigNumber(0),
        investmentObjective: "spot trade",
      });

      enqueueSnackbar("Listing Created", { variant: "success" });
      closeDialog();
      return;
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error creating listing: ${err.message ? err.message : err.toString()}`,
      );
      enqueueSnackbar(
        `error creating listing: ${err.message ? err.message : err.toString()}`,
        { variant: "error" },
      );
    }
    setListingCreationInProgress(false);
  }, [
    closeDialog,
    authContext,
    asset,
    formData.marketMechanisms,
    enqueueSnackbar,
  ]);

  const [
    listingMarketMechanismUpdateInProgress,
    setListingMarketMechanismUpdateInProgress,
  ] = useState(false);
  const handleUpdateListingMarketMechanism = useCallback(async () => {
    if (!listing) {
      console.error("expected listing to be set");
      return;
    }

    setListingMarketMechanismUpdateInProgress(true);
    try {
      for (const m of formData.marketMechanisms) {
        await ListingUpdater.UpdateListingMarketMechanism({
          context: authContext,
          listingID: listing.id,
          marketMechanism: m,
        });
      }
      enqueueSnackbar("Listing Updated", { variant: "success" });
      closeDialog();
      return;
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error updating listing: ${err.message ? err.message : err.toString()}`,
      );
      enqueueSnackbar(
        `error updating listing: ${err.message ? err.message : err.toString()}`,
        { variant: "error" },
      );
    }
    setListingMarketMechanismUpdateInProgress(false);
  }, [
    closeDialog,
    authContext,
    listing,
    formData.marketMechanisms,
    enqueueSnackbar,
  ]);

  const [listingActivationInProgress, setListingActivationInProgress] =
    useState(false);
  const handleActivateListing = useCallback(async () => {
    if (!listing) {
      console.error("expected listing to be set");
      return;
    }

    setListingActivationInProgress(true);

    try {
      await ListingStateControllerActivateAsset({
        context: authContext,
        listingID: listing.id,
        lastActionAnnotation: formData.lastActionAnnotation,
      });

      enqueueSnackbar("Listing Activated", { variant: "success" });
      closeDialog();
      return;
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error activating listing: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `error activating listing: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setListingActivationInProgress(false);
  }, [
    formData.lastActionAnnotation,
    closeDialog,
    authContext,
    listing,
    enqueueSnackbar,
  ]);

  const [listingDeactivationInProgress, setListingDeactivationInProgress] =
    useState(false);
  const handleDeactivateListing = useCallback(async () => {
    if (!listing) {
      console.error("expected listing to be set");
      return;
    }

    setListingDeactivationInProgress(true);
    try {
      await ListingStateControllerDeactivateListing({
        context: authContext,
        listingID: listing.id,
        lastActionAnnotation: formData.lastActionAnnotation,
      });
      enqueueSnackbar("Listing Deactivated", { variant: "success" });
      closeDialog();
      return;
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `error deactivating listing: ${
          err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `error deactivating listing: ${
          err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
    }
    setListingDeactivationInProgress(false);
  }, [
    formData.lastActionAnnotation,
    closeDialog,
    authContext,
    listing,
    enqueueSnackbar,
  ]);

  const apiInProgress =
    listingCreationInProgress ||
    listingMarketMechanismUpdateInProgress ||
    listingActivationInProgress ||
    listingDeactivationInProgress;

  return (
    <StyledDialog open maxWidth={"lg"}>
      <DialogTitle classes={{ root: classes.dialogTitleRootOverride }}>
        <Grid container direction={"row"} spacing={1} alignItems={"center"}>
          <Grid item>
            <ListingIcon />
          </Grid>
          <Grid item>
            <Typography variant={"h5"} children={"Manage Listing"} />
          </Grid>
          {apiInProgress && (
            <Grid item>
              <CircularProgress size={20} />
            </Grid>
          )}
        </Grid>
        <Grid container direction={"row"} spacing={1} alignItems={"center"}>
          <Grid item>
            <Tooltip title={"Close"} placement={"top"}>
              <IconButton
                id={"listingManagementDialog-close-iconButton"}
                size={"small"}
                onClick={props.closeDialog}
                disabled={apiInProgress}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Box>
          <Typography variant={"h6"}>Base Token</Typography>
          <Autocomplete
            isOptionEqualToValue={(option, value) => option === value}
            fullWidth
            id={"listingManagementDialog-baseToken-autoComplete"}
            disabled={formDataValidationInProgress || apiInProgress}
            getOptionLabel={(option: TokenViewModel) =>
              `${option.token.code} - ${option.issuer}`
            }
            options={ledgerTokenViewReadResponseForBaseToken.models}
            loading={ledgerTokenViewReadLoadingForBaseToken}
            renderOption={(addProps, option: TokenViewModel) => (
              <li {...addProps}>
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing(1),
                  })}
                >
                  <TokenIconViewUpload
                    disableChangeIcon
                    size={23}
                    token={option.token}
                  />
                  <Typography
                    children={`${option.token.code} - ${option.issuer}`}
                  />
                </Box>
              </li>
            )}
            value={(() => {
              if (!baseToken) {
                return null;
              }
              const selected =
                ledgerTokenViewReadResponseForBaseToken.models.find((m) =>
                  m.token.isEqualTo(baseToken),
                );
              return selected ? selected : null;
            })()}
            onChange={(a, selected: TokenViewModel | null) => {
              if (selected) {
                setBaseToken(new Token(selected.token));
              }
            }}
            onInputChange={(e, newValue, reason) => {
              if (reason === "reset") {
                return;
              }
              if (newValue === "") {
                setLedgerTokenViewReadRequestForBaseToken({
                  ...ledgerTokenViewReadRequestForBaseToken,
                  criteria: {
                    tokenCategory: tokenCategoryCriterion,
                  },
                });
              } else {
                setLedgerTokenViewReadRequestForBaseToken({
                  ...ledgerTokenViewReadRequestForBaseToken,
                  criteria: {
                    tokenCategory: tokenCategoryCriterion,
                    "token.code": TextSubstringCriterion(newValue),
                  },
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                id={"listingManagementDialog-baseToken-autoCompleteTextField"}
                InputProps={{
                  ...params.InputProps,
                  fullWidth: true,
                  placeholder: "Select...",
                  className: classes.selectFormField,
                  startAdornment: baseToken ? (
                    <InputAdornment position={"start"}>
                      <TokenIconViewUpload
                        disableChangeIcon
                        size={23}
                        token={baseToken}
                      />
                    </InputAdornment>
                  ) : undefined,
                  endAdornment: (
                    <InputAdornment position={"end"}>
                      {ledgerTokenViewReadLoadingForBaseToken ? (
                        <CircularProgress size={23} />
                      ) : (
                        <SearchIcon color={"disabled"} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
      </DialogContent>
      {(() => {
        if (loadingListing) {
          return (
            <>
              <DialogContent classes={{ root: classes.dialogContent }}>
                <Skeleton width={"100%"} height={100} />
              </DialogContent>
              <Divider />
              <DialogContent classes={{ root: classes.dialogContent }}>
                <Skeleton width={"100%"} height={100} />
              </DialogContent>
            </>
          );
        }

        if (
          matchingListingNotSet ||
          !baseTokenViewModel ||
          !listing ||
          !asset
        ) {
          return (
            <DialogContent classes={{ root: classes.dialogContent }}>
              <Typography variant={"subtitle2"} color={"textSecondary"}>
                Select base token to begin
              </Typography>
            </DialogContent>
          );
        }

        return (
          <>
            <Divider />
            <DialogContent classes={{ root: classes.dialogContent }}>
              <Typography variant={"h6"}>Asset Details</Typography>
              <Grid container>
                <Grid item xs={6}>
                  <TextField
                    id={"listingManagementDialog-assetDetails-Name-textField"}
                    label={"Name"}
                    value={asset.assetName()}
                    variant={"standard"}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id={
                      "listingManagementDialog-assetDetails-shortName-textField"
                    }
                    label={"Short Name"}
                    value={asset.assetShortName()}
                    variant={"standard"}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id={
                      "listingManagementDialog-assetDetails-category-textField"
                    }
                    label={"Category"}
                    value={baseTokenViewModel.tokenCategory}
                    variant={"standard"}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id={"listingManagementDialog-assetDetails-issuer-textField"}
                    label={"Issuer"}
                    value={baseTokenViewModel.issuer}
                    variant={"standard"}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant={"caption"}
                    color={"textSecondary"}
                    children={"Description"}
                  />
                  <Typography variant={"body2"} children={assetDescription} />
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogContent classes={{ root: classes.dialogContent }}>
              {formData.marketMechanisms.map((m, mechanismIdx) => (
                <Grid
                  container
                  direction={"column"}
                  spacing={1}
                  key={mechanismIdx}
                >
                  <Grid item>
                    <Grid
                      container
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                    >
                      <Grid item>
                        <Typography variant={"h6"}>
                          {`${m.type} Listing Details`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ListingStateChip state={listing.state} />
                      </Grid>
                      {!!listing.state && (
                        <Grid item>
                          <Tooltip
                            placement={"top"}
                            title={listing.lastActionAnnotation}
                          >
                            <InfoIcon className={classes.infoIcon} />
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant={"caption"} color={"textSecondary"}>
                      Quote Parameters
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{ maxHeight: 0.19 * window.innerHeight }}
                    className={cx(classes.quoteParametersTable, "meshScroll")}
                  >
                    {m.quoteParameters.map((qp, quoteParameterIdx) => (
                      <div
                        key={mechanismIdx + "-" + quoteParameterIdx}
                        className={classes.quoteParametersTableRow}
                      >
                        <div className={classes.row}>
                          <TokenIconViewUpload
                            disableChangeIcon
                            size={23}
                            token={qp.quoteToken}
                          />
                          <Typography children={qp.quoteToken.code} />
                        </div>
                        <TextNumField
                          id={`listingManagementDialog-listingDetails-marketMechanism-${mechanismIdx}-quoteParameter${quoteParameterIdx}-minDealSize-textNumField`}
                          label={"Min Deal Size"}
                          value={qp.minimumDealSize.value}
                          disallowNegative
                          noDecimalPlaces={7}
                          onChange={(e) =>
                            formDataUpdate.updateQuoteParameterMinDealSize({
                              mechanismIdx,
                              quoteParameterIdx,
                              amount: qp.minimumDealSize.setValue(
                                e.target.value,
                              ),
                            })
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                <Typography
                                  color={"textSecondary"}
                                  variant={"body1"}
                                  children={baseToken?.code}
                                />
                              </InputAdornment>
                            ),
                          }}
                          disabled={apiInProgress}
                          error={
                            !!formDataValidationResult.fieldValidations[
                              `marketMechanism-${mechanismIdx}-quoteParam-${quoteParameterIdx}-minimumDealSize`
                            ]
                          }
                          helperText={
                            formDataValidationResult.fieldValidations[
                              `marketMechanism-${mechanismIdx}-quoteParam-${quoteParameterIdx}-minimumDealSize`
                            ]
                          }
                        />
                        <TextNumField
                          id={`listingManagementDialog-listingDetails-marketMechanism-${mechanismIdx}-quoteParameter${quoteParameterIdx}-maxDealSize-textNumField`}
                          label={"Max Deal Size"}
                          value={qp.maximumDealSize.value}
                          disallowNegative
                          noDecimalPlaces={7}
                          onChange={(e) =>
                            formDataUpdate.updateQuoteParameterMaxDealSize({
                              mechanismIdx,
                              quoteParameterIdx,
                              amount: qp.maximumDealSize.setValue(
                                e.target.value,
                              ),
                            })
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                <Typography
                                  color={"textSecondary"}
                                  variant={"body1"}
                                  children={baseToken?.code}
                                />
                              </InputAdornment>
                            ),
                          }}
                          disabled={apiInProgress}
                          error={
                            !!formDataValidationResult.fieldValidations[
                              `marketMechanism-${mechanismIdx}-quoteParam-${quoteParameterIdx}-maximumDealSize`
                            ]
                          }
                          helperText={
                            formDataValidationResult.fieldValidations[
                              `marketMechanism-${mechanismIdx}-quoteParam-${quoteParameterIdx}-maximumDealSize`
                            ]
                          }
                        />
                        <Tooltip
                          title={"Remove quote parameter"}
                          placement={"top"}
                        >
                          <span>
                            <IconButton
                              id={`listingManagementDialog-listingDetails-marketMechanism-${mechanismIdx}-quoteParameter${quoteParameterIdx}-remove-iconButton`}
                              size={"small"}
                              onClick={() => {
                                if (!baseToken) {
                                  console.error(
                                    "expected base token to be set",
                                  );
                                  return;
                                }

                                // remove quote parameter
                                formDataUpdate.removeQuoteParameter({
                                  mechanismIdx,
                                  quoteParameterIdx,
                                });

                                // update quote token search params
                                setLedgerTokenViewReadRequestForQuoteToken({
                                  ...ledgerTokenViewReadRequestForQuoteToken,
                                  criteria: {
                                    tokenCategory: tokenCategoryCriterion,
                                    "token.code": TextNINListCriterion([
                                      ...m.quoteParameters
                                        .filter(
                                          (_, qpIdx) =>
                                            qpIdx !== quoteParameterIdx,
                                        )
                                        .map(
                                          (existingQp) =>
                                            existingQp.quoteToken.code,
                                        ),
                                      baseToken.code,
                                    ]),
                                    "token.network": TextExactCriterion(
                                      baseToken.network,
                                    ),
                                  },
                                });
                              }}
                              disabled={apiInProgress}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                    ))}
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option === value}
                      id={
                        "listingManagementDialog-selectQuoteToken-autoComplete"
                      }
                      fullWidth
                      disabled={apiInProgress}
                      getOptionLabel={(option: TokenViewModel) =>
                        `${option.token.code} - ${option.issuer}`
                      }
                      options={ledgerTokenViewReadResponseForQuoteToken.models}
                      loading={ledgerTokenViewReadLoadingForQuoteToken}
                      renderOption={(addProps, option: TokenViewModel) => (
                        <li {...addProps} key={addProps.id}>
                          <Box
                            sx={(theme) => ({
                              display: "flex",
                              alignItems: "center",
                              gap: theme.spacing(1),
                            })}
                          >
                            <TokenIconViewUpload
                              disableChangeIcon
                              size={23}
                              token={option.token}
                            />
                            <Typography
                              children={`${option.token.code} - ${option.issuer}`}
                            />
                          </Box>
                        </li>
                      )}
                      value={null}
                      inputValue={quoteTokenSearchText}
                      onChange={(
                        a,
                        selected: TokenViewModel | null,
                        reason: string,
                      ) => {
                        if (reason !== "selectOption") {
                          return;
                        }
                        if (!baseToken) {
                          console.error("expected base token to be set");
                          return;
                        }

                        // clear quote token search text to clear input on select
                        setQuoteTokenSearchText("");

                        if (selected) {
                          // update potential new quote token search criteria
                          setLedgerTokenViewReadRequestForQuoteToken({
                            criteria: {
                              tokenCategory: tokenCategoryCriterion,
                              "token.code": TextNINListCriterion([
                                ...m.quoteParameters.map(
                                  (qp) => qp.quoteToken.code,
                                ),
                                baseToken.code,
                                selected.token.code,
                              ]),
                              "token.network": TextExactCriterion(
                                baseToken.network,
                              ),
                            },
                          });

                          // add quote parameter
                          formDataUpdate.addQuoteParameter({
                            mechanismIdx,
                            quoteToken: selected.token,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          id={
                            "listingManagementDialog-selectQuoteToken-autoCompleteTextField"
                          }
                          onChange={(e) => {
                            if (!baseToken) {
                              console.error("expected base token to be set");
                              return;
                            }

                            // update quote token search text since input component of autocomplete is controlled
                            setQuoteTokenSearchText(e.target.value);

                            if (e.target.value === "") {
                              setLedgerTokenViewReadRequestForQuoteToken({
                                ...ledgerTokenViewReadRequestForQuoteToken,
                                criteria: {
                                  tokenCategory: tokenCategoryCriterion,
                                  "token.code": TextNINListCriterion([
                                    ...m.quoteParameters.map(
                                      (qp) => qp.quoteToken.code,
                                    ),
                                    baseToken.code,
                                  ]),
                                  "token.network": TextExactCriterion(
                                    baseToken.network,
                                  ),
                                },
                              });
                            } else {
                              setLedgerTokenViewReadRequestForQuoteToken({
                                ...ledgerTokenViewReadRequestForQuoteToken,
                                criteria: {
                                  tokenCategory: tokenCategoryCriterion,
                                  $and: [
                                    {
                                      "token.code": TextNINListCriterion([
                                        ...m.quoteParameters.map(
                                          (qp) => qp.quoteToken.code,
                                        ),
                                        baseToken.code,
                                      ]),
                                    },
                                    {
                                      "token.code": TextSubstringCriterion(
                                        e.target.value,
                                      ),
                                    },
                                  ],
                                  "token.network": TextExactCriterion(
                                    baseToken.network,
                                  ),
                                },
                              });
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            placeholder: ledgerTokenViewReadLoadingForQuoteToken
                              ? "Loading..."
                              : m.quoteParameters.length
                                ? ledgerTokenViewReadResponseForQuoteToken
                                    .models.length
                                  ? "Select Another Quote Token..."
                                  : "No More Quote Tokens Available on Network"
                                : ledgerTokenViewReadResponseForQuoteToken
                                      .models.length
                                  ? "Select a Quote Token..."
                                  : "No Quote Tokens Available on Network",
                            className: classes.selectFormField,
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {ledgerTokenViewReadLoadingForQuoteToken ? (
                                  <CircularProgress size={23} />
                                ) : (
                                  <SearchIcon color={"disabled"} />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          helperText={
                            formDataValidationResult.fieldValidations
                              .quoteTokens
                          }
                          error={
                            !!formDataValidationResult.fieldValidations
                              .quoteTokens
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              ))}
            </DialogContent>
            <DialogActions>
              <Tooltip
                placement={"top"}
                title={(() => {
                  switch (true) {
                    case formDataValidationInProgress:
                      return "Validation in progress";
                    case !formDataValidationResult.valid:
                      return "Please ensure that all fields have been completed correctly";
                    default:
                      return "";
                  }
                })()}
              >
                <span>
                  {(() => {
                    if (!(listing || baseToken)) {
                      return null;
                    }

                    if (listing.id) {
                      // listing already exits

                      // if market mechanism has changed then show 'save changes button'
                      if (
                        !isEqual(
                          listing.marketMechanisms,
                          formData.marketMechanisms,
                        )
                      ) {
                        return (
                          <Tooltip
                            placement={"top"}
                            title={(() => {
                              switch (true) {
                                case formDataValidationInProgress:
                                  return "Validation in progress";
                                case !formDataValidationResult.valid:
                                  return "Please ensure that all fields have been completed correctly";
                                default:
                                  return "";
                              }
                            })()}
                          >
                            <span>
                              <Button
                                id={
                                  "listingManagementDialog-saveChanges-button"
                                }
                                variant={"contained"}
                                color={"primary"}
                                children={"save changes"}
                                onClick={handleUpdateListingMarketMechanism}
                                disabled={
                                  formDataValidationInProgress ||
                                  !formDataValidationResult.valid ||
                                  apiInProgress
                                }
                              />
                            </span>
                          </Tooltip>
                        );
                      }

                      // market mechanism not changed, show activate or deactivate button
                      if (listing.state === ListingState.Active) {
                        return (
                          <Button
                            id={"listingManagementDialog-deactivate-button"}
                            variant={"contained"}
                            color={"primary"}
                            children={"deactivate"}
                            disabled={apiInProgress}
                            onClick={() =>
                              formDataUpdate.listingStateChangeAction(
                                ListingStateChangeAction.Deactivate,
                              )
                            }
                          />
                        );
                      } else {
                        return (
                          <Button
                            id={"listingManagementDialog-activate-button"}
                            variant={"contained"}
                            color={"primary"}
                            children={"activate"}
                            disabled={apiInProgress}
                            onClick={() =>
                              formDataUpdate.listingStateChangeAction(
                                ListingStateChangeAction.Activate,
                              )
                            }
                          />
                        );
                      }
                    } else {
                      // listing does not exist, show create button
                      return (
                        <Tooltip
                          placement={"top"}
                          title={(() => {
                            switch (true) {
                              case formDataValidationInProgress:
                                return "Validation in progress";
                              case !formDataValidationResult.valid:
                                return "Please ensure that all fields have been completed correctly";
                              default:
                                return "";
                            }
                          })()}
                        >
                          <span>
                            <Button
                              id={"listingManagementDialog-create-button"}
                              variant={"contained"}
                              color={"primary"}
                              children={"create"}
                              onClick={handleCreateListing}
                              disabled={
                                formDataValidationInProgress ||
                                !formDataValidationResult.valid ||
                                apiInProgress
                              }
                            />
                          </span>
                        </Tooltip>
                      );
                    }
                  })()}
                </span>
              </Tooltip>
            </DialogActions>

            {formData.listingStateChangeAction && (
              <StyledDialog
                open
                maxWidth={"lg"}
                onClose={() =>
                  formDataUpdate.listingStateChangeAction(undefined)
                }
              >
                <DialogTitle
                  classes={{
                    root: classes.lastActionAnnotationDialogTitleRootOverride,
                  }}
                >
                  <Grid
                    container
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <NoteIcon />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant={"h5"}
                        children={"Action Annotation"}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        size={"small"}
                        disabled={!formDataValidationResult.valid}
                        children={formData.listingStateChangeAction}
                        onClick={() => {
                          switch (formData.listingStateChangeAction) {
                            case ListingStateChangeAction.Activate:
                              handleActivateListing().finally();
                              break;
                            case ListingStateChangeAction.Deactivate:
                              handleDeactivateListing().finally();
                              break;
                          }
                          formDataUpdate.listingStateChangeAction(undefined);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip title={"Close"} placement={"top"}>
                        <IconButton
                          size={"small"}
                          onClick={() =>
                            formDataUpdate.listingStateChangeAction(undefined)
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContentRootOverride}>
                  <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                      <Typography color={"textSecondary"}>
                        Provide a note around why listing &apos;
                        {formData.listingStateChangeAction}&apos; is being
                        performed.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextareaAutosize
                        minRows={9}
                        maxRows={9}
                        value={formData.lastActionAnnotation}
                        onChange={(e) => {
                          let newValue: string = e.target.value;
                          if (newValue.length >= 250) {
                            newValue = newValue.slice(0, 250);
                          }
                          formDataUpdate.lastActionAnnotation(newValue);
                        }}
                        placeholder={"Note"}
                        className={cx(classes.textArea, {
                          [classes.textAreaError]:
                            formDataValidationResult.fieldValidations
                              .lastActionAnnotation,
                        })}
                      />
                      {formDataValidationResult.fieldValidations
                        .lastActionAnnotation ? (
                        <FormHelperText
                          error
                          children={
                            formDataValidationResult.fieldValidations
                              .lastActionAnnotation
                          }
                        />
                      ) : (
                        <FormHelperText>
                          {250 - formData.lastActionAnnotation.length}{" "}
                          Characters Left
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>
              </StyledDialog>
            )}
          </>
        );
      })()}
    </StyledDialog>
  );
};

export { ListingManagementDialog };
