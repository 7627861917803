import React, { useState } from "react";
import { useErrorContext } from "context/Error";
import { Amount } from "james/ledger";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useApplicationContext } from "context/Application/Application";
import { TextNumField } from "components/FormFields";
import {
  Model as TokenViewModel,
  useRead as userLedgerTokenViewRead,
} from "james/views/ledgerTokenView";
import { Query } from "james/search/query";
import {
  TextListCriterion,
  TextNEExactCriterion,
  TextSubstringCriterion,
} from "james/search/criterion";
import { AllStellarNetworks, MaxSetLimitAmount, NativeAssetTokenCode } from "james/stellar";
import { TokenCategory } from "james/views/ledgerTokenView/Model";
import { TransactionNotificationChannel } from "james/ledger/TransactionNotificationChannel";
import {
  TransactionSucceededNotificationTypeName,
  TransactionFailedNotificationTypeName,
  TransactionSubmissionResolutionFailedNotificationTypeName,
  TransactionSucceededNotification,
  TransactionFailedNotification,
  TransactionSubmissionResolutionFailedNotification,
} from "james/ledger/TransactionNotifications";
import { useNotificationContext } from "context/Notification/Notification";
import { Notification } from "james/notification/Notification";
import { useAPIContext } from "context/API";
import {
  SetLimitRequest,
  SetLimitResponse,
} from "@mesh/common-js/dist/ledger/stellarops/accountOperator_pb";
interface SetLimitSectionProps {
  accountID: string;
}

const defaultCriteria = {
  "token.network": TextListCriterion(AllStellarNetworks),
  tokenCategory: TextNEExactCriterion(TokenCategory.LiquidityPoolShares),
};

export const SetLimitSection = ({ accountID }: SetLimitSectionProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { authContext } = useApplicationContext();
  const { enqueueSnackbar } = useSnackbar();
  const { errorContextErrorTranslator } = useErrorContext();
  const { registerNotificationCallback } = useNotificationContext();
  const [submitting, setSubmitting] = useState(false);

  const [
    selectedTokenViewModelForLimitSetting,
    setSelectedTokenViewModelForLimitSetting,
  ] = useState<TokenViewModel | null>(null);
  const [limit, setLimit] = useState<Amount>(new Amount());

  const {
    ledger: {
      stellarops: { stellarOpsAccountOperator },
    },
  } = useAPIContext();

  const {
    readResponse: ledgerTokenViewReadResponse,
    readRequest: ledgerTokenViewReadRequest,
    setReadRequest: setLedgerTokenViewReadRequest,
  } = userLedgerTokenViewRead({
    query: new Query({
      ...new Query(),
      limit: 10,
    }),
    criteria: defaultCriteria,
  });

  const handleSetLimit = async () => {
    setSubmitting(true);
    let setLimitResponse: SetLimitResponse;
    try {
      setLimitResponse = await stellarOpsAccountOperator.setLimit(
        new SetLimitRequest()
          .setLimit(limit.toFutureAmount())
          .setAccountid(accountID)
          .setContext(authContext.toFuture()),
      );
      enqueueSnackbar("Limit Set", { variant: "info" });
    } catch (e) {
      const err = errorContextErrorTranslator.translateError(e);
      console.error(
        `unable to set limit on account: ${err.message ? err.message : err.toString()
        }`,
      );
      enqueueSnackbar(
        `Unable to set limit on account: ${err.message ? err.message : err.toString()
        }`,
        { variant: "error" },
      );
      setSubmitting(false);
      return;
    }

    try {
      const deregister = await registerNotificationCallback(
        new TransactionNotificationChannel({
          transactionID: setLimitResponse.getTransactionid(),
          private: true,
        }),
        [
          TransactionSucceededNotificationTypeName,
          TransactionFailedNotificationTypeName,
          TransactionSubmissionResolutionFailedNotificationTypeName,
        ],
        (n: Notification) => {
          // check transaction id
          if (
            n instanceof TransactionSucceededNotification &&
            n.transactionID === setLimitResponse.getTransactionid()
          ) {
            enqueueSnackbar(`Setting Limit Successful`, {
              variant: "success",
            });
          } else if (
            n instanceof TransactionFailedNotification &&
            n.transactionID === setLimitResponse.getTransactionid()
          ) {
            enqueueSnackbar("Error! Setting Limit Failed", {
              variant: "error",
            });
          } else if (
            n instanceof TransactionSubmissionResolutionFailedNotification &&
            n.transactionID === setLimitResponse.getTransactionid()
          ) {
            enqueueSnackbar(
              "Warning! Something has gone wrong while setting limit",
              { variant: "warning" },
            );
          }
          deregister();
          setSubmitting(false);
        },
      );
    } catch (e) {
      console.error("error registering");
      enqueueSnackbar(
        "Warning! Unable to Register for Set Limit Ledger Notifications - Please Refresh to Monitor.",
        { variant: "warning" },
      );
    }
  };

  return (
    <Box
      sx={{
        mb: 2,
      }}
    >
      <Typography variant="h4" fontWeight={"bold"} id="set-limit-heading-accounts">
        Set Limit
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: { sm: 2, xs: 0 },
          mt: { sm: 0, xs: 1 },
          alignItems: "center",
          flexDirection: { sm: "row", xs: "column" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            columnGap: { sm: 2, xs: 0 },
            width: { xs: "100%", sm: 520 },
          }}
        >
          <Autocomplete
            sx={{ width: { sm: "60%" } }}
            id="set-limit-asset-combo"
            fullWidth={smDown}
            disabled={submitting}
            renderInput={(props) => (
              <TextField
                {...props}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setLedgerTokenViewReadRequest({
                      ...ledgerTokenViewReadRequest,
                      criteria: defaultCriteria,
                    });
                  } else {
                    setLedgerTokenViewReadRequest({
                      ...ledgerTokenViewReadRequest,
                      criteria: {
                        ...defaultCriteria,
                        "token.code": TextSubstringCriterion(e.target.value),
                      },
                    });
                  }
                }}
              />
            )}
            options={ledgerTokenViewReadResponse.models.filter((m) => m.token.code !== NativeAssetTokenCode)}
            getOptionLabel={(option: TokenViewModel) =>
              `${option.token.code} - ${option.issuer}`
            }
            value={selectedTokenViewModelForLimitSetting}
            onChange={(a, selected: TokenViewModel | null) => {
              if (selected) {
                setLimit(selected.token.newAmountOf(limit.value));
                setSelectedTokenViewModelForLimitSetting(selected);
              }
            }}
          />
          <TextNumField
            id="set-limit-amount"
            fullWidth={smDown}
            value={limit.value}
            disabled={submitting}
            onChange={(e) => setLimit(limit.setValue(e.target.value))}
            InputProps={{
              endAdornment: (
                <Button
                  disabled={submitting}
                  children="set max"
                  onClick={() => setLimit(limit.setValue(MaxSetLimitAmount))}
                />
              ),
            }}
          />
        </Box>
        <Button
          id="set-limit-button"
          fullWidth={smDown}
          variant="outlined"
          sx={{ width: { sm: 160 }, height: 38, mt: { sm: 0.5 } }}
          disabled={
            submitting ||
            !selectedTokenViewModelForLimitSetting ||
            !limit ||
            limit.isUndefined()
          }
          onClick={handleSetLimit}
        >
          Set Limit{" "}
          {submitting && <CircularProgress size={16} sx={{ ml: 1 }} />}
        </Button>
      </Box>
    </Box>
  );
};
