import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Model } from "@mesh/common-js/dist/views/stellarAccountView/model_pb";
import { useSnackbar } from "notistack";
import meshMiniLogo from "assets/images/logo/meshLogoNoWords.svg";
import { BankAccount } from "james/banking";
import { LedgerAccountCategory } from "james/ledger";
import { MobileNavigator } from "components/BottomNavigation/MobileNavigator";
import { ClientKYCStatus } from "james/client";
import { PortfolioBanner } from "components/Banner/Portfolio/PortfolioBanner";
import { useAccountContext } from "context/Account/Account";
import { WalletDialogKYCPlaceHolder } from "components/PlaceHolderScreens/WalletDialogKYCPlaceHolder";
import { useIsMounted } from "hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SkeletonLoader } from "components/SkeletonLoader";
import { BankAccountRepository } from "james/banking/bankAccountRepository";
import { TextExactCriterion } from "james/search/criterion";
import { ClientKind } from "james/client/Client";
import { useApplicationContext } from "context/Application/Application";
import { Helmet } from "react-helmet-async";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import {
  Model as MarketSubscriptionOrderViewModel,
  Reader as MarketSubscriptionOrderViewReader,
} from "james/views/marketSubscriptionOrderView";
import { NewSorting, Query } from "james/search/query";
import { SubscriptionOrderState } from "james/market/SubscriptionOrder";
import { AccountCard } from "views/Accounts/components/AccountCard/AccountCard";
import { AccountDetails } from "views/Accounts/components/AccountDetails/AccountDetails";
import { useErrorContext } from "context/Error";

interface AccountContextType {
  bankAccounts: BankAccount[] | undefined;
  refreshBankAccounts: () => void;
}

export const AccountsContext = React.createContext({
  bankAccounts: undefined,
} as AccountContextType);

export function Accounts() {
  const { errorContextErrorTranslator } = useErrorContext();
  const theme = useTheme();
  const { authContext, myClientKYCStatus, viewConfiguration, loginClaims } =
    useApplicationContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [accBankAccounts, setAccBankAccounts] = useState<
    BankAccount[] | undefined
  >(undefined);
  const [refreshBankAccount, setRefreshBankAccount] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { stellarAccountContext } = useAccountContext();
  const [viewAccountListOnly, setViewAccountListOnly] = useState(false);
  const { Banner } = useAppNoticeContext();
  const [subscriptionOrders, setSubscriptionOrders] = useState<
    MarketSubscriptionOrderViewModel[]
  >([]);

  // order and sort available account view models as required on UI
  const accountViewModels = useMemo(() => {
    const tradingAccounts: Model[] = [];
    const otherAccounts: Model[] = [];
    stellarAccountContext.accounts.forEach((acc) => {
      if (acc.getLabel() === LedgerAccountCategory.Trading) {
        tradingAccounts.push(acc);
      } else {
        otherAccounts.push(acc);
      }
    });
    return [...tradingAccounts.reverse(), ...otherAccounts];
  }, [stellarAccountContext.accounts]);

  // set selected account ID in URL on first render if not yet set
  useEffect(() => {
    // only 'auto-select' an account if one is not yet already set
    const accountIDFromURL = searchParams.get("id");
    if (accountIDFromURL) {
      return;
    }

    // check if account statement option was set
    const statement = searchParams.get("focusStatement");

    // only 'auto-select' an account if sorted account view models is set
    if (!accountViewModels.length) {
      return;
    }

    // get first account in sorted list and select it
    const firstAccount = accountViewModels[0];
    if (firstAccount && isMounted()) {
      navigate(
        `/accounts?id=${firstAccount.getId()}&focusStatement=${statement ? "true" : "false"}`,
        {
          state: location.state,
          replace: true,
        },
      );
    }
  }, [isMounted, accountViewModels]);

  // get selected account from ID in URL
  const selectedAccount = useMemo(
    () =>
      stellarAccountContext.accounts.find(
        (acc) => acc.getId() === searchParams.get("id"),
      ),
    [stellarAccountContext.accounts, enqueueSnackbar, searchParams],
  );

  // remove invalid account IDs from url
  useEffect(() => {
    // only check for invalid account IDs if sorted account view models is set
    if (!accountViewModels.length) {
      return;
    }

    // only confirm account ID in URL is valid only if selected account is set
    const accountIDFromURL = searchParams.get("id");
    if (accountIDFromURL && !selectedAccount) {
      navigate("/accounts", {
        state: location.state,
        replace: true,
      });
    }
  }, [selectedAccount, searchParams, accountViewModels]);

  // show warning about access to limited functionality
  useEffect(() => {
    if (myClientKYCStatus !== ClientKYCStatus.VerifiedStatus) {
      enqueueSnackbar(
        "You are not verified! You will have limited access to functionality.",
        {
          variant: "info",
        },
      );
    }
  }, [loginClaims, enqueueSnackbar]);

  // this effect needs to run every time account selection changes
  useEffect(() => {
    let mounted = true;

    //
    // data retrievals that needs to happen once an account has been selected
    //

    // search for bank accounts owned by the group that owns the selected account
    if (selectedAccount) {
      (async () => {
        try {
          if (mounted) {
            setAccBankAccounts(undefined);
          }
          // search for bank account only when you have the bank account view role
          if (viewConfiguration.BankAccountViewer) {
            const response = await BankAccountRepository.SearchBankAccounts({
              context: authContext,
              criteria: {
                ownerID: TextExactCriterion(selectedAccount.getOwnerid()),
              },
            });

            if (mounted) {
              setAccBankAccounts(response.records);
            }
          }
        } catch (e) {
          const err = errorContextErrorTranslator.translateError(e);
          console.error(
            `error determining if user is signatory on account: ${
              err.message ? err.message : err.toString()
            }`,
          );
          enqueueSnackbar("Error Determining Signatory Status", {
            variant: "error",
          });
          return;
        }
      })();
    }

    return () => {
      mounted = false;
    };
  }, [selectedAccount, refreshBankAccount, authContext]);

  useEffect(() => {
    (async () => {
      await Promise.all([
        (async () => {
          if (selectedAccount?.getOwnerid()) {
            try {
              const response = await MarketSubscriptionOrderViewReader.Read({
                context: authContext,
                query: new Query({
                  limit: 15,
                  offset: 0,
                  sorting: [NewSorting("id", "asc")],
                }),
                criteria: {
                  state: TextExactCriterion(
                    SubscriptionOrderState.AwaitingSettlement,
                  ),
                  ownerID: TextExactCriterion(
                    selectedAccount?.getOwnerid() ?? "",
                  ),
                },
              });

              setSubscriptionOrders(response.models);
            } catch (e) {
              const err = errorContextErrorTranslator.translateError(e);
              console.error(
                `error reading market subscription order view models: ${
                  err.message ? err.message : err.toString()
                }`,
                enqueueSnackbar(
                  `Error reading market subscription order view models:: ${
                    err.message ? err.message : err.toString()
                  }`,
                  { variant: "error" },
                ),
              );
            }
          }
        })(),
      ]);
    })();
  }, [selectedAccount]);

  const getFromLocation = () => {
    // if the "from" location state has been set on the location object,
    // redirect there, otherwise redirect to "/"
    type LocationState = {
      from: Location;
    };
    const from = (location.state as LocationState)?.from;
    return from ? `${from.pathname}${from.search}` : "/";
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          backgroundColor: "unset",
          position: "fixed",
          overflow: "hidden",
          height: "100%",
        },
      }}
      open
      fullScreen
    >
      <Helmet>
        <title>Mesh | Accounts</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Mesh account view where you can see your full portfolio and your account balances on one page"
        />
      </Helmet>
      {Banner}
      <DialogTitle
        sx={{
          borderBottom: "none",
          [theme.breakpoints.down("sm")]: {
            boxShadow: 24,
          },
        }}
      >
        <Grid
          sx={{ alignItems: "center" }}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid
            item
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              gridColumnGap: theme.spacing(1),
            })}
          >
            <Box
              sx={{
                height: 32,
                width: 32,
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <img alt="" src={meshMiniLogo} />
            </Box>
            <Typography variant="h5">Manage Accounts</Typography>
          </Grid>
          {/* Please fix me Liam */}
          <Grid item>
            <IconButton
              size={"small"}
              id={"accountManagementDialog-close-button"}
              onClick={() => {
                navigate(getFromLocation(), { replace: true });
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {
        // Show navigation back to account list if on mobile
        isMobile && selectedAccount && accountViewModels.length > 1 && (
          <DialogTitle sx={{ p: 0 }}>
            <Box
              sx={{
                width: "100%",
                position: "sticky",
                top: 0,
                display: "flex",
                alignItems: "flexStart",
                flexDirection: "column",
                backgroundColor: "#111126",
                gap: theme.spacing(2),
                gridGap: theme.spacing(2),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "32px",
                  alignItems: "center",
                }}
              >
                <Link
                  id={"mobileWallet-myMeshAccount-text"}
                  target={"_blank"}
                  children={"My Mesh Accounts"}
                  underline={"hover"}
                  sx={{
                    alignSelf: "flex-start",
                    margin: theme.spacing(1, 3),
                  }}
                  onClick={() => {
                    setViewAccountListOnly(true);
                    navigate("/accounts", {
                      state: location.state,
                    });
                  }}
                />
              </Box>
            </Box>
          </DialogTitle>
        )
      }
      <DialogContent
        className={!isMobile ? "meshScroll" : ""}
        sx={{
          backgroundColor: theme.palette.background.default,
          padding: "unset !important",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          overflowX: "hidden",
          [theme.breakpoints.down("sm")]: {
            position: "sticky",
          },
        }}
      >
        {myClientKYCStatus === ClientKYCStatus.VerifiedStatus ? (
          <AccountsContext.Provider
            value={{
              bankAccounts: accBankAccounts,
              refreshBankAccounts: () => setRefreshBankAccount((val) => !val),
            }}
          >
            <PortfolioBanner />
            <Box
              sx={(theme) => ({
                width: "100vw",
                maxWidth: {
                  lg:
                    isDesktop &&
                    loginClaims.clientKind === ClientKind.CompanyType
                      ? theme.breakpoints.values.lg - 8
                      : "814px",
                  sm: "814px",
                  xs: "unset",
                },
                padding: { sm: theme.spacing(0, 2) },
              })}
            >
              {!isMobile && (
                <Box
                  sx={(theme) => ({
                    width: "100%",
                    margin: theme.spacing(3, 0, 0, 0),
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: theme.spacing(1, 0, 2, 0),
                    })}
                  >
                    <SkeletonLoader
                      loading={
                        accountViewModels.length === 0 ||
                        (isDesktop && !selectedAccount)
                      }
                    >
                      <Typography sx={{ fontWeight: 600 }} variant="h4">
                        {accountViewModels.length > 1
                          ? "My Mesh Accounts"
                          : "My Mesh Account"}
                      </Typography>
                    </SkeletonLoader>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ paddingRight: "16px", height: "20px" }}>
                        <SkeletonLoader
                          loading={
                            accountViewModels.length === 0 ||
                            (isDesktop && !selectedAccount)
                          }
                        >
                          <Link
                            target={"_blank"}
                            children={
                              isDesktop
                                ? "Go Back"
                                : selectedAccount
                                  ? "My Mesh Accounts"
                                  : "Go Back"
                            }
                            underline={"hover"}
                            onClick={() => {
                              if (!isDesktop && selectedAccount) {
                                navigate("/accounts", {
                                  state: location.state,
                                  replace: true,
                                });
                                setViewAccountListOnly(true);
                              } else {
                                navigate(getFromLocation(), {
                                  replace: true,
                                });
                              }
                            }}
                          />
                        </SkeletonLoader>
                      </Box>
                      {!(
                        accountViewModels.length === 0 ||
                        (isDesktop && !selectedAccount)
                      ) && (
                        <Box
                          sx={{
                            borderRight: `1px solid ${theme.palette.text.disabled}`,
                            height: "10px",
                          }}
                        ></Box>
                      )}
                      <Box sx={{ paddingLeft: "16px", height: "20px" }}>
                        <SkeletonLoader
                          loading={
                            accountViewModels.length === 0 ||
                            (isDesktop && !selectedAccount)
                          }
                        >
                          <Link
                            sx={{
                              justifySelf: "end",
                              textDecorationColor: "unset",
                            }}
                            target="_blank"
                            href={"https://support.mesh.trade/support/home"}
                            children={"Need help?"}
                            underline={"hover"}
                          />
                        </SkeletonLoader>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  minHeight: "calc(100% - 104px)",
                }}
              >
                {((isDesktop &&
                  loginClaims.clientKind === ClientKind.CompanyType) ||
                  viewAccountListOnly) && (
                  <Box
                    sx={{
                      height: "auto",
                      alignSelf: "flex-start",
                      overflowY: "overlay",
                      overflowX: "hidden",
                      width: "100%",
                      maxWidth: {
                        lg: "418px",
                        sm: "846px",
                        xs: "100%",
                      },
                    }}
                  >
                    {isMobile && (
                      <Typography
                        sx={{
                          justifySelf: "end",
                          textAlign: "center",
                          fontWeight: (theme) =>
                            theme.typography.fontWeightBold + " !important",
                          margin: (theme) => theme.spacing(3, 0, 3, 0),
                        }}
                        variant="h3"
                      >
                        My Mesh Accounts
                      </Typography>
                    )}
                    <Box
                      sx={(theme) => ({
                        [theme.breakpoints.down("lg")]: {
                          display: "flex",
                          flexDirection: "column",
                        },
                        alignItems: "center",
                        width: "100%",
                        minHeight: "calc(100vh - 350px)",
                        height: "auto",
                        [theme.breakpoints.down("sm")]: {
                          minHeight: "calc(100vh - 403px)",
                          height: "auto",
                        },
                      })}
                      className={"meshScroll"}
                    >
                      {accountViewModels.length > 0 ? (
                        accountViewModels.map((model) => (
                          <React.Fragment key={model.getId()}>
                            <AccountCard
                              onClick={() => {
                                if (!isDesktop) {
                                  setViewAccountListOnly(false);
                                }
                                navigate(`/accounts?id=${model.getId()}`, {
                                  state: location.state,
                                  replace: true,
                                });
                              }}
                              selected={
                                selectedAccount?.getId() === model.getId()
                              }
                              accountModel={model}
                            />
                          </React.Fragment>
                        ))
                      ) : (
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateRows: "repeat(3,70px)",
                            alignItems: "end",
                          }}
                        >
                          <Skeleton width={"394px"} height={"90px"} />
                          <Skeleton width={"394px"} height={"90px"} />
                          <Skeleton width={"394px"} height={"90px"} />
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
                {!viewAccountListOnly && (
                  <AccountDetails
                    accountModel={selectedAccount}
                    singleAccount={accountViewModels.length === 1}
                    subscriptionOrders={subscriptionOrders}
                  />
                )}
              </Box>
            </Box>
          </AccountsContext.Provider>
        ) : (
          <WalletDialogKYCPlaceHolder fromLocation={getFromLocation()} />
        )}
      </DialogContent>
      {isMobile && (
        <DialogTitle
          sx={{
            p: 0,
            height: 75,
          }}
        >
          <MobileNavigator />
        </DialogTitle>
      )}
    </Dialog>
  );
}
