import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Model } from "@mesh/common-js/dist/views/stellarRegisteredLiquidityPoolView/model_pb";
import { useReadManyModel } from "@mesh/common-js-react/dist/views/stellarRegisteredLiquidityPoolView/modelReader_meshjsreact";
import { BPTable } from "components/Table";
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Icon,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowDownward as DepositIcon,
  OpenInNew as OpenInNewIcon,
  Refresh as ReloadIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import { Token } from "james/ledger";
import { Column } from "components/Table/BPTable/BPTable";
import { IconViewUpload } from "components/Ledger/Token/IconViewUpload";
import { formatTextNum } from "utilities/number";
import {
  getNetworkServer,
  LiquidityPoolTokenToReserveTokens,
  tokenToAsset,
} from "james/stellar";
import { TextNumField } from "components/FormFields";
import { DepositDialog } from "./DepositDialog";
import { RegisterDialog } from "./RegisterDialog";
import { useAccountContext } from "context/Account/Account";
import { SkeletonLoader } from "components/SkeletonLoader";
import { useApplicationContext } from "context/Application/Application";
import { useAppNoticeContext } from "context/AppNotice/AppNotice";
import { ReadManyModelRequest } from "@mesh/common-js/dist/views/stellarRegisteredLiquidityPoolView/modelReader_pb";
import { Query } from "@mesh/common-js/dist/search/query_pb";
import { Query as OldQuery } from "james/search/query/Query";
import { Sorting, SortingOrder } from "@mesh/common-js/dist/search/sorting_pb";
import { Token as StellarToken } from "@mesh/common-js/dist/stellar/token_pb";
import {
  amountIsUndefined,
  futureTokenFromStellarToken,
  futureAmountFromStellarAmount,
  newAmountOfStellarToken,
  futureTokenToStellarToken,
} from "@mesh/common-js/dist/ledger";
import { Network } from "@mesh/common-js/dist/stellar/network_pb";
import { useAPIContext } from "context/API";
import { Amount } from "components/Ledger/Amount";
import { Amount as LedgerAmount } from "james/ledger/Amount";
import {
  bigNumberToDecimal,
  decimalToBigNumber,
} from "@mesh/common-js/dist/num";
import { protobufTimestampToDayjs } from "@mesh/common-js/dist/googleProtobufConverters";
import { BigNumber } from "bignumber.js";

const PREFIX = "Table";

const classes = {
  row: `${PREFIX}-row`,
  textFieldInner: `${PREFIX}-textFieldInner`,
  lessPadding: `${PREFIX}-lessPadding`,
  endAdornment: `${PREFIX}-endAdornment`,
  hoverPointer: `${PREFIX}-hoverPointer`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.row}`]: {
    maxWidth: 450,
    margin: theme.spacing(0, 0.2),
    display: "grid",
    gridTemplateColumns: "1fr 15px 1fr",
    alignItems: "center",
    justifyItems: "center",
    columnGap: theme.spacing(0.5),
  },

  [`& .${classes.textFieldInner}`]: {
    color: theme.palette.text.tertiary,
    backgroundColor: alpha(theme.palette.background.default, 0.5),
  },

  [`& .${classes.lessPadding}`]: {
    padding: theme.spacing(0.5, 0, 0.5, 0.5),
  },

  [`& .${classes.endAdornment}`]: {
    paddingRight: theme.spacing(0.5),
  },

  [`& .${classes.hoverPointer}`]: {
    cursor: "pointer",
  },
}));

const initialQuery = new Query()
  .setLimit(15)
  .setOffset(0)
  .setSortingList([
    new Sorting().setField("id").setOrder(SortingOrder.ASC_SORTING_ORDER),
  ]);

export function Table() {
  const {
    views: { stellarRegisteredLiquidityPoolViewModelReader },
  } = useAPIContext();
  const { authContext, viewConfiguration } = useApplicationContext();
  const {
    readManyModelRequest,
    readManyModelResponse,
    setReadModelRequest,
    readManyModelLoading: readLoading,
  } = useReadManyModel(stellarRegisteredLiquidityPoolViewModelReader, {
    initialRequest: new ReadManyModelRequest()
      .setContext(authContext.toFuture())
      .setQuery(initialQuery),
  });
  const { NotificationBannerHeight: noticeBannerHeight } =
    useAppNoticeContext();
  const { stellarAccountContext } = useAccountContext();
  const [
    depositDialogLiquidityPoolSharesToken,
    setDepositDialogLiquidityPoolSharesToken,
  ] = useState<StellarToken | undefined>(undefined);
  const [registerPoolDialogOpen, setRegisterPoolDialogOpen] = useState(false);
  const liquidityPoolsViewConfig =
    viewConfiguration?.Ledger?.Stellar?.["Liquidity Pools"] ?? {};

  return (
    <>
      <BPTable
        disableSelect
        height={window.innerHeight - noticeBannerHeight - 200}
        title="Registered Liquidity Pools"
        loading={readLoading}
        data={readManyModelResponse.getRecordsList()}
        totalNoRecords={readManyModelResponse.getTotal()}
        query={OldQuery.fromFutureQuery(readManyModelRequest.getQuery())}
        onQueryChange={(query) =>
          setReadModelRequest(
            readManyModelRequest.setQuery(query.toFutureQuery()),
          )
        }
        toolBarControls={(() => {
          const controlsToRender: ReactNode[] = [];

          if (liquidityPoolsViewConfig.Register) {
            controlsToRender.push(
              <Button
                variant="contained"
                color="primary"
                id="liquidityPoolsTable-registerPool-button"
                children="Register Pool"
                onClick={() => setRegisterPoolDialogOpen(true)}
              />,
            );
          }

          controlsToRender.push(
            <Tooltip title="Reload">
              <span>
                <IconButton
                  id="liquidityPoolsTable-refresh-iconButton"
                  size="small"
                  onClick={() =>
                    setReadModelRequest(
                      readManyModelRequest.setQuery(initialQuery),
                    )
                  }
                >
                  <ReloadIcon />
                </IconButton>
              </span>
            </Tooltip>,
          );

          return controlsToRender;
        })()}
        columns={(() => {
          const cols: Column[] = [
            {
              field: "tokenIcon",
              sortable: false,
              label: "",
              accessor: (data) => (
                <Box sx={{ marginRight: "4px" }}>
                  <IconViewUpload
                    disableChangeIcon
                    token={Token.fromFutureToken(
                      futureTokenFromStellarToken(
                        (data as Model)
                          .getLiquiditypoolregistration()
                          ?.getToken() ?? new StellarToken(),
                      ),
                    )}
                  />
                </Box>
              ),
            },
            {
              field: "amountA",
              sortable: false,
              label: "Amount A",
              accessor: (data) => {
                const model = data as Model;
                try {
                  return (
                    <Amount
                      id={`liquidityPoolsTable-${model.getLiquiditypoolregistration()?.getToken()?.getCode()}-amountA-amountField`}
                      amount={
                        amountIsUndefined(
                          futureAmountFromStellarAmount(
                            model.getLiquiditypool()?.getTotalshares(),
                          ),
                        )
                          ? futureAmountFromStellarAmount(
                              newAmountOfStellarToken(
                                LiquidityPoolTokenToReserveTokens(
                                  model
                                    .getLiquiditypoolregistration()
                                    ?.getToken() ?? new StellarToken(),
                                )[0],
                                "0",
                              ),
                            )
                          : LedgerAmount.fromFutureAmount(
                              futureAmountFromStellarAmount(
                                model.getLiquiditypool()?.getAmounta(),
                              ),
                            )
                      }
                    />
                  );
                } catch (e) {
                  console.error(`error rendering amountA field: ${e}`);
                  return "!";
                }
              },
            },
            {
              field: "amountB",
              sortable: false,
              label: "Amount B",
              accessor: (data) => {
                const model = data as Model;
                try {
                  return (
                    <Amount
                      id={`liquidityPoolsTable-${model.getLiquiditypoolregistration()?.getToken()?.getCode()}-amountB-amountField`}
                      amount={
                        amountIsUndefined(
                          futureAmountFromStellarAmount(
                            model.getLiquiditypool()?.getTotalshares(),
                          ),
                        )
                          ? futureAmountFromStellarAmount(
                              newAmountOfStellarToken(
                                LiquidityPoolTokenToReserveTokens(
                                  model
                                    .getLiquiditypoolregistration()
                                    ?.getToken() ?? new StellarToken(),
                                )[1],
                                "0",
                              ),
                            )
                          : LedgerAmount.fromFutureAmount(
                              futureAmountFromStellarAmount(
                                model.getLiquiditypool()?.getAmountb(),
                              ),
                            )
                      }
                    />
                  );
                } catch (e) {
                  console.error(`error rendering amountB field: ${e}`);
                  return "!";
                }
              },
            },
            {
              field: "product",
              sortable: false,
              label: "Product",
              accessor: (data) => {
                const liquidityPool = (data as Model).getLiquiditypool();
                return amountIsUndefined(
                  futureAmountFromStellarAmount(
                    liquidityPool?.getTotalshares(),
                  ),
                )
                  ? "-"
                  : decimalToBigNumber(
                        liquidityPool?.getTotalshares()?.getValue(),
                      ).toNumber()
                    ? formatTextNum(
                        decimalToBigNumber(
                          liquidityPool?.getAmounta()?.getValue(),
                        ).multipliedBy(
                          decimalToBigNumber(
                            liquidityPool?.getAmountb()?.getValue(),
                          ),
                        ),
                        { noDecimalPlaces: 2 },
                      )
                    : "-";
              },
            },
            {
              field: "price",
              sortable: false,
              label: "Price",
              minWidth: 200,
              accessor: (data) => {
                const model = data as Model;
                if (
                  amountIsUndefined(
                    futureAmountFromStellarAmount(
                      model.getLiquiditypool()?.getTotalshares(),
                    ),
                  )
                ) {
                  return "-";
                }
                try {
                  return (
                    <LittlePriceComponent
                      liquidityPoolSharesToken={Token.fromFutureToken(
                        futureTokenFromStellarToken(
                          model.getLiquiditypoolregistration()?.getToken(),
                        ),
                      )}
                    />
                  );
                } catch (e) {
                  console.error(`error rendering little price component: ${e}`);
                  return "-";
                }
              },
            },
            {
              field: "lastModified",
              sortable: false,
              label: "Last Modified",
              accessor: (data) => {
                return amountIsUndefined(
                  futureAmountFromStellarAmount(
                    (data as Model).getLiquiditypool()?.getTotalshares(),
                  ),
                )
                  ? "-"
                  : protobufTimestampToDayjs(
                      (data as Model).getLiquiditypool()?.getLastmodified(),
                    ).format("DD/MM/YYYY HH:mm:ss");
              },
            },
          ];

          if (liquidityPoolsViewConfig.Operate) {
            cols.push({
              field: "",
              label: "",
              sortable: false,
              accessor: (data) => (
                <SkeletonLoader
                  sx={{ transform: "none" }}
                  loading={stellarAccountContext.loading}
                >
                  <Button
                    id={`liquidityPoolsTable-${(data as Model).getLiquiditypoolregistration()?.getToken()?.getCode()}-deposit-button`}
                    variant="contained"
                    color="primary"
                    children="deposit"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDepositDialogLiquidityPoolSharesToken(
                        (data as Model)
                          .getLiquiditypoolregistration()
                          ?.getToken(),
                      );
                    }}
                    endIcon={
                      <Icon>
                        <DepositIcon />
                      </Icon>
                    }
                  />
                </SkeletonLoader>
              ),
            });
          }

          cols.push({
            field: "",
            label: "",
            minWidth: 40,
            sortable: false,
            accessor: (data) => (
              <Tooltip
                placement="top"
                title="View liquidity pool in Stellar Expert"
              >
                <IconButton
                  size="small"
                  id={`liquidityPoolsTable-${(data as Model).getLiquiditypoolregistration()?.getToken()?.getCode()}-openInNew-iconButton`}
                  onClick={() =>
                    window.open(
                      `https://stellar.expert/explorer/${
                        (data as Model)
                          .getLiquiditypoolregistration()
                          ?.getToken()
                          ?.getNetwork() === Network.STELLAR_TEST_SDF_NETWORK
                          ? "testnet"
                          : "public"
                      }/liquidity-pool/${(data as Model).getLiquiditypoolregistration()?.getLiquiditypoolid()}`,
                      "_blank",
                    )
                  }
                >
                  <OpenInNewIcon />
                </IconButton>
              </Tooltip>
            ),
          });

          return cols;
        })()}
      />

      {registerPoolDialogOpen && (
        <RegisterDialog
          closeDialog={() => setRegisterPoolDialogOpen(false)}
          onRegister={() =>
            setReadModelRequest(
              readManyModelRequest.setCriteriaList(
                readManyModelRequest.getCriteriaList(),
              ),
            )
          }
        />
      )}
      {depositDialogLiquidityPoolSharesToken && (
        <DepositDialog
          closeDialog={() =>
            setDepositDialogLiquidityPoolSharesToken(undefined)
          }
          liquidityPoolSharesToken={depositDialogLiquidityPoolSharesToken}
        />
      )}
    </>
  );
}

type LittlePriceComponentProps = {
  liquidityPoolSharesToken: Token;
};

enum LastChangedField {
  A,
  B,
  Neither,
}

function LittlePriceComponent(props: LittlePriceComponentProps) {
  const [tokenA, tokenB] = useMemo(
    () =>
      LiquidityPoolTokenToReserveTokens(
        futureTokenToStellarToken(
          props.liquidityPoolSharesToken.toFutureToken(),
        ),
      ),
    [props.liquidityPoolSharesToken],
  );
  const server = useMemo(
    () => getNetworkServer(tokenA.getNetwork()),
    [tokenA.getNetwork()],
  );
  const [tokenAAmount, setTokenAAmount] = useState(
    newAmountOfStellarToken(tokenA, "1"),
  );
  const [tokenBAmount, setTokenBAmount] = useState(
    newAmountOfStellarToken(tokenB, "0"),
  );
  const [lastChanged, setLastChanged] = useState<LastChangedField>(
    LastChangedField.A,
  );
  const [calculationInProgress, setCalculationInProgress] = useState(false);
  const calculationTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [calculationError, setCalculationError] = useState<string | undefined>(
    "",
  );
  useEffect(() => {
    // if neither amount is last changed then do nothing
    if (lastChanged === LastChangedField.Neither) {
      return;
    }

    // the changed amount should be > 0
    if (
      (lastChanged === LastChangedField.A && !tokenAAmount.getValue()) ||
      (lastChanged === LastChangedField.B && !tokenBAmount.getValue())
    ) {
      return;
    }

    setCalculationInProgress(true);
    clearTimeout(calculationTimeoutRef.current);
    calculationTimeoutRef.current = setTimeout(async () => {
      switch (lastChanged) {
        case LastChangedField.A:
          try {
            const response = await server
              .strictReceivePaths(
                [tokenToAsset(tokenBAmount.getToken() ?? new StellarToken())],
                tokenToAsset(tokenAAmount.getToken() ?? new StellarToken()),
                decimalToBigNumber(tokenAAmount.getValue()).toString(),
              )
              .call();
            if (response.records.length) {
              setTokenBAmount(tokenBAmount.setValue());
              setCalculationError(undefined);
            } else {
              setCalculationError("no path found");
            }
          } catch (e) {
            console.error(`error getting price: ${e}`);
            setCalculationError(`error getting price: ${e}`);
          }
          break;

        case LastChangedField.B:
          try {
            const response = await server
              .strictReceivePaths(
                [tokenToAsset(tokenAAmount.getToken() ?? new StellarToken())],
                tokenToAsset(tokenBAmount.getToken() ?? new StellarToken()),
                decimalToBigNumber(tokenBAmount.getValue()).toString(),
              )
              .call();
            if (response.records.length) {
              setTokenAAmount(
                tokenAAmount.setValue(
                  bigNumberToDecimal(
                    new BigNumber(response.records[0].source_amount),
                  ),
                ),
              );
              setCalculationError(undefined);
            } else {
              console.error("no path found");
              setCalculationError("no path found");
            }
          } catch (e) {
            console.error(`error getting price: ${e}`);
            setCalculationError(`error getting price: ${e}`);
          }
          break;
      }
      setLastChanged(LastChangedField.Neither);
      setCalculationInProgress(false);
    }, 500);
  }, [tokenAAmount, tokenBAmount, lastChanged, server]);

  return (
    <Root className={classes.row} onClick={(e) => e.stopPropagation()}>
      <TextNumField
        fullWidth
        id={`liquidityPoolsTable-priceCell-${tokenA.getCode()}-textNumField`}
        noDecimalPlaces={7}
        disallowNegative
        value={decimalToBigNumber(tokenAAmount.getValue())}
        onChange={(e) => {
          setTokenAAmount(
            tokenAAmount.setValue(
              bigNumberToDecimal(new BigNumber(e.target.value)),
            ),
          );
          setLastChanged(LastChangedField.A);
        }}
        InputProps={{
          classes: {
            adornedEnd: classes.endAdornment,
            input: classes.lessPadding,
          },
          className: classes.textFieldInner,
          endAdornment: (
            <InputAdornment position="end" className={classes.endAdornment}>
              <Typography
                variant="body1"
                color="textSecondary"
                children={tokenA.getCode()}
              />
            </InputAdornment>
          ),
        }}
      />
      {calculationInProgress ? (
        <CircularProgress size={9} />
      ) : calculationError ? (
        <Tooltip placement="top" title={calculationError}>
          <Icon color="error" className={classes.hoverPointer}>
            <WarningIcon />
          </Icon>
        </Tooltip>
      ) : (
        <Tooltip
          placement="top"
          title={
            !tokenAAmount.getValue() || !tokenBAmount.getValue()
              ? ""
              : `Price is: ${formatTextNum(
                  decimalToBigNumber(tokenAAmount.getValue()).div(
                    decimalToBigNumber(tokenBAmount.getValue()),
                  ),
                  { noDecimalPlaces: 7 },
                )} ${tokenAAmount.getToken()?.getCode()} or ${formatTextNum(
                  decimalToBigNumber(tokenBAmount.getValue()).div(
                    decimalToBigNumber(tokenAAmount.getValue()),
                  ),
                  { noDecimalPlaces: 7 },
                )} ${tokenBAmount.getToken()?.getCode}`
          }
        >
          <Typography
            className={classes.hoverPointer}
            variant="h2"
            children="≈"
          />
        </Tooltip>
      )}
      <TextNumField
        fullWidth
        id={`liquidityPoolsTable-priceCell-${tokenB.getCode()}-textNumField`}
        noDecimalPlaces={7}
        disallowNegative
        value={decimalToBigNumber(tokenBAmount.getValue())}
        onChange={(e) => {
          setTokenBAmount(
            tokenBAmount.setValue(
              bigNumberToDecimal(new BigNumber(e.target.value)),
            ),
          );
          setLastChanged(LastChangedField.B);
        }}
        InputProps={{
          classes: {
            adornedEnd: classes.endAdornment,
            input: classes.lessPadding,
          },
          className: classes.textFieldInner,
          endAdornment: (
            <InputAdornment position="end">
              <Typography
                variant="body1"
                color="textSecondary"
                children={tokenB.getCode()}
              />
            </InputAdornment>
          ),
        }}
      />
    </Root>
  );
}
